import React from 'react'
import styled from "styled-components";
import Menu from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import { FaEnvelope, FaVideo, FaPhone, FaQuestionCircle, FaPlayCircle } from "react-icons/fa";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import YouTube from 'react-youtube';
import { Helmet } from 'react-helmet';

const Faqs = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [vidoeID, setVideoID] = React.useState("");
    const onPlayerReady = (event) => {
        event.target.pauseVideo();
    }
    return (
        <>
            <Helmet>
                <title>Prodocs | FAQs</title>
            </Helmet>
            <Menu />
            <Wrapper>
                <div className='faqs_heading_section'>
                    <h2 className='faqs_heading'>Need Help?</h2>
                    <p className='faqs_text' style={{ marginBottom: '16px' }}>Check out our frequently Asked Questions and tutorial videos below. If you still have questions or suggestions for how we can improve</p>
                    <p className='faqs_text' style={{ marginBottom: '0px' }}>ProDocs contact us at:</p>
                    <div className="contact-info col xs-12 md-9 lg-8 xl-7">
                        <a className="site-phone col auto padding-right" href="tel:8662540289">
                            <FaPhone />
                            866-254-0289
                        </a>
                        <a className="site-email col auto padding-left" href="mailto:info@myscopetech.com">
                            <FaEnvelope />
                            info@myscopetech.com
                        </a>
                    </div>
                </div>
                <div className="accordion-section">
                    <h2 className="section-title">
                        <div className="col auto no-ws-hack">Frequently Asked Questions  <FaQuestionCircle /></div>
                    </h2>
                    <div>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How do I create my first template?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    There is a helpful video tutorial on that subject as well as others that can be found by clicking the "Tutorials" tab.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Is there a limit to the number of estimates I can create?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    No. You can create as many as you’d like from any finished Scope report.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How long will my estimates be available once created?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Just like your Scope reports, they will be available indefinitely, until you delete them.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Can I include labor, material costs, or overhead and profit (O & P) to my estimate without showing them on the document?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes and yes. Labor, material costs, and O & P can be applied at the line item level. You also have the option of including O & P separately in the ‘Taxes & Markups’ section at the bottom of your estimate.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>How do I add a waste factor to a line item?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Choose the item you’d like to add a waste factor to and click Edit. Once in the Line Item Editor you can apply a waste factor which will automatically recalculate your quantity for that line item.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Why would I want to create templates?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Once you start building your first document, you can save it as a template to reuse over and over again. It will save you time by including all of the line items and customization you need in your estimate. Once you have templates saved that you want to use again, you can save steps by just hitting “Generate” from a Scope in the My Scopes screen and it will populate all your Scope report data into that template.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>What is a default template?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    A default template is one that you can assign to be used for all of your estimates created that are associated to specific Scope types (i.e. RoofScope, SidingScope, GutterScope, etc.). (add link to specific video). Of course, you can edit this template as needed on a case by case basis.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Can I create a ProDocs estimate without a Scope report?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes. ProDocs is designed to work seamlessly with your Scope Reports to save you time and money, however, we understand that there are instances where you might not require a Scope Report for some projects. Please contact us at info@myscopetech.com or call 877-MY-SCOPE, and we can walk you through using ProDocs without a Scope Report.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Can I include multiple trades (like roofing and siding) on the same estimate?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Absolutely! That is one of the best features of ProDocs. You can save time by including multiple Scope reports for the same property in one estimate. (add link to specific video)
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>What is the cost of ProDocs?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    We have monthly subscription options to suit your business needs and budget. Please call us at 877-MY-SCOPE to find out more about our ProDocs pricing options and to get started with a plan that is right for you.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Can I also generate work orders and invoices from ProDocs?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes! Both of these features will be coming to ProDocs soon.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                className='heading'
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Is there any additional training available for my team?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    If you have a team of people using ProDocs regularly, please contact our Customer Service at 877-MY-SCOPE for information regarding ProDocs training.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <div className="video-section">
                    <h2 className="video-tutorials">
                        <div>Video Tutorials</div>
                        <FaVideo />
                    </h2>
                    <div>
                        <div className='yt-video-title'>
                            <a data_video-id="jvwwbG2qh1o" onClick={() => {
                                setVideoID("jvwwbG2qh1o")
                                handleOpen()
                            }} >How to get started with ProDocs</a>
                            <FaPlayCircle />
                        </div>
                        <div className='yt-video-title'>
                            <a data_video-id="AxObP55GZek" onClick={() => {
                                setVideoID("AxObP55GZek")
                                handleOpen()
                            }} >How to create new line items in the ProDocs Estimate editor</a>
                            <FaPlayCircle />
                        </div>
                        <div className='yt-video-title'>
                            <a data_video-id="D0IKj01bfgY" onClick={() => {
                                setVideoID("D0IKj01bfgY")
                                handleOpen()
                            }} >How to edit and customize line items in ProDocs</a>
                            <FaPlayCircle />
                        </div>
                        <div className='yt-video-title'>
                            <a data_video-id="6uFD_VS6Sp0" onClick={() => {
                                setVideoID("6uFD_VS6Sp0")
                                handleOpen()
                            }} >How to add and edit taxes and markups in ProDocs Estimate editor</a>
                            <FaPlayCircle />
                        </div>
                        <div className='yt-video-title'>
                            <a data_video-id="AHb6X3yPCy4" onClick={() => {
                                setVideoID("AHb6X3yPCy4")
                                handleOpen()
                            }} >How to create and edit templates in ProDocs
                            </a>
                            <FaPlayCircle />
                        </div>
                        <div>
                            <Modal
                                className='modal-section'
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <div>
                                    <YouTube className='yt-modal' videoId={vidoeID} onReady={onPlayerReady} />;
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </Wrapper>
            <Footer />
        </>
    )
}

const Wrapper = styled.div`
max-width: 1280px;
margin: auto;

.yt-video-title {
        margin-bottom: 16px;

    a {
        margin-right: 5px;
        font-size: 18px;
    }
}

.modal-yt-video {
    position: absolute;
    left: 50%;
}

.video-section{
    margin-bottom: 32px;

    @media(max-width: 1024px) {
        padding-inline: 20px;
    }
}

.video-tutorials {
    display: flex;
    align-items: center;
    margin-bottom : 26px;

    div{
        font-size: 18px;
        color: #414141;
        font-weight: bold;
        margin-right: 5px;
    }

    svg {
        width:16px;
        height: 16px;
    }
}

.faqs_heading_section {
    text-align: center;
    margin-top: 32px;
    padding-bottom : 16px;
    margin-bottom : 32px;
    border-bottom: solid 2px #bdbdbd;

    @media(max-width: 1024px) {
        padding-inline: 20px;
    }
}

.contact-info {
    @media(max-width: 1024px) {
        display: flex;
        flex-direction: column;

        a {
            margin-bottom:  10px;
        }
    }
}
.faqs_heading {
    color: #414141;
    font-family: 'RobotoBlack';
    font-size: 28px;
    font-weight: bold;
}
.faqs_text {
    color: #414141;
    font-size: 16px
}

.contact-info {
    margin-block : 24px;
}
.site-phone{
    font-size: 16px;
    color: #414141;

    svg {
        transform: rotate(90deg);
        margin-right: 5px
    }
}

.site-email{
    font-size: 16px;
    color: #414141;

    svg {
        margin-right: 5px
    }
}

.section-title {
    div {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom : 24px;
    }
}
    .MuiTypography-root {
    font-size: 14.4px;
    }

.MuiAccordion-root {
    box-shadow : unset;
    border: unset;
}
.heading .MuiTypography-root {
    font-size: 18px;
}
.accordion-section {
    margin-bottom: 32px;
}
`

export default Faqs;
